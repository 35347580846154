body {
  margin: 0;
  background-color: #f7f7f7;
}

::-webkit-scrollbar {
  width:16px;
  }
::-webkit-scrollbar-track {
  background: #EFF1F6;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
border-radius:2px;
background:#B1B7C2;
}